<template>
	<layout-main>
		<div class="home">
			<!-- Header -->
			<div class="subheader">
				<div class="subheader-main">
					<h3>{{ $t('labels.statistics') }}</h3>
					<span class="separator-line"></span>
					<div class="breadcrumbs">
						<router-link to="/" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
						<span class="separator-dot"></span>
						<router-link to="/tickets/statistics-new" class="breadcrumbs-link"> {{ $t('labels.statistics') }}</router-link>
					</div>
				
					<div class="ml-auto d-flex justify-content-center">
						<info-box class="mx-3 mt-1" field="Ärenden avd Statistik - toggle ärenden/händelser" />
						<b-form-group>
							<b-form-radio-group
								id="switch-index"
								v-model="selectedStatistic"
								:options="statistics"
								button-variant="outline-primary"
								buttons
								@change="switchStatistic"
							></b-form-radio-group>
						</b-form-group>
					</div>
				</div>
			</div>
			<!-- Header -->
			<reactive-base app="comment_index" :credentials="user.user.es_username + ':' + user.user.es_password" :url="elasticUrl">
				<div class="row">
					<div class="col-12">
						<div class="widget">
							<div class="widget-header statistics-filter">
								<div class="widget-header-label">
									<h3>{{ $t('labels.selected-filters') }}</h3>
								</div>
								<div class="widget-header-toolbar">
									<div class="actions">
										<SelectedFilters class="bb-1 curent-filters pl-4 m-0" :clearAllLabel="this.$t('labels.clear')" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<v-guard :permissions="['tickets-page-statistics-use-filter']">
						<div class="col-12">
							<div class="widget">
								<div class="widget-header statistics-filter">
									<multi-list
										componentId="yearFilter"
										:filterLabel="this.$t('labels.year')"
										:title="this.$t('labels.year-event-registered')"
										dataField="year.raw"
										className="horizontal-filter year-filter"
										sortBy="asc"
										:showSearch="false"
										:defaultQuery="() => defaultQueryFilter()"
										:URLParams="true"
										:react="{ and: getOtherFilterIds('yearFilter') }"
										@valueChange="setSelectedYears"
									>
										<template slot="renderItem" slot-scope="{ label }">
											<div>{{ label }}</div>
										</template>
									</multi-list>

									<multi-list
										componentId="quarterFilter"
										:filterLabel="this.$t('labels.quarter')"
										:title="this.$t('labels.quarter-event-registered')"
										dataField="quarter.raw"
										className="horizontal-filter quarter-filter"
										sortBy="asc"
										:showSearch="false"
										:URLParams="true"
										:react="{ and: getOtherFilterIds('quarterFilter') }"
									>
										<template slot="renderItem" slot-scope="{ label }">
											<div>
												{{ label }}
											</div>
										</template>
									</multi-list>

									<multi-list
										componentId="monthFilter"
										:filterLabel="this.$t('labels.month')"
										:title="this.$t('labels.month-event-registered')"
										dataField="month.raw"
										className="horizontal-filter month-filter"
										sortBy="asc"
										:size="12"
										:showSearch="false"
										:URLParams="true"
										:transformData="sortMonthByLabel"
										:react="{ and: getOtherFilterIds('monthFilter') }"
										
									>
										<template slot="renderItem" slot-scope="{ label, count }">
											<div>
												{{ label }}
											</div>
										</template>
									</multi-list>
								</div>
							</div>
						</div>

						<div class="col-lg-12 col-xl-3 order-lg-1 order-xl-1">
							<div class="widget widget-facets statistics-vertical">
								<div class="widget-header mb-3">
									<div class="widget-header-label">
										<h3><i class="fas fa-circle-notch fa-spin" v-show="isLoading"></i>{{ $t('labels.filtration') }}</h3>
									</div>
									<div class="widget-header-toolbar">
										<div class="actions"></div>
									</div>
								</div>

								<div class="widget-body p-0">
									<multi-dropdown-list
										className="filter-vertical"
										:URLParams="true"
										:showSearch="true"
										:placeholder="this.$t('labels.select')"
										sortBy="asc"
										:size="1000"
										:title="this.$t('labels.gender')"
										componentId="genderFilter"
										:filterLabel="this.$t('labels.gender')"
										nestedField="ticket"
										:dataField="$field('ticket.gender')+'.raw'"
										:react="{ and: getOtherFilterIds('genderFilter') }"
										:defaultQuery="() => defaultQueryFilter()"
									/>

									
									<multi-dropdown-list
										className="filter-vertical"
										:URLParams="true"
										:showSearch="true"
										:placeholder="this.$t('labels.select')"
										sortBy="asc"
										:size="1000"
										:title="this.$t('labels.age-group')"
										componentId="ageFilter"
										:filterLabel="this.$t('labels.age')"
										nestedField="ticket"
										:dataField="$field('ticket.age')+'.raw'"
										:react="{ and: getOtherFilterIds('ageFilter') }"
										:defaultQuery="() => defaultQueryFilter()"
									/>
									
									<multi-dropdown-list
										className="filter-vertical"
										:URLParams="true"
										:showSearch="true"
										:placeholder="this.$t('labels.select')"
										sortBy="asc"
										:size="1000"
										:title="this.$t('labels.category')"
										componentId="categoryFilter"
										:filterLabel="this.$t('labels.category')"
										nestedField="ticket"
										:dataField="$field('ticket.category')+'.raw'"
										:react="{ and: getOtherFilterIds('categoryFilter') }"
										:defaultQuery="() => defaultQueryFilter()"
									/>

									<multi-dropdown-list
										className="filter-vertical"
										:URLParams="true"
										:showSearch="true"
										:placeholder="this.$t('labels.select')"
										sortBy="asc"
										:size="1000"
										:title="this.$t('labels.first-contact')"
										componentId="firstContactFilter"
										:filterLabel="this.$t('labels.first-contact')"
										nestedField="ticket"
										:dataField="$field('ticket.first_contact')+'.raw'"
										:react="{ and: getOtherFilterIds('firstContactFilter') }"
										:defaultQuery="() => defaultQueryFilter()"
									/>

									<multi-dropdown-list
										className="filter-vertical"
										:URLParams="true"
										:showSearch="true"
										:placeholder="this.$t('labels.select')"
										sortBy="asc"
										:size="1000"
										:title="this.$t('labels.crime')"
										componentId="crimeFilter"
										:filterLabel="this.$t('labels.crime')"
										nestedField="ticket"
										:dataField="$field('ticket.crime')+'.raw'"
										:react="{ and: getOtherFilterIds('crimeFilter') }"
										:defaultQuery="() => defaultQueryFilter()"
									/>

							
									<multi-dropdown-list
										className="filter-vertical"
										:URLParams="true"
										:showSearch="true"
										:placeholder="this.$t('labels.select')"
										sortBy="asc"
										:title="this.$t('labels.special-circumstances')"
										componentId="crimeRelationSubFilter"
										:filterLabel="this.$t('labels.special-circumstances')"
										nestedField="crime_relation"
										:dataField="$field('crime_relation.label') + '.raw'"
										:react="{ and: getOtherFilterIds('crimeRelationSubFilter') }"
										:defaultQuery="() => defaultQueryFilter()"
									/>

									<multi-dropdown-list
										className="filter-vertical"
										:URLParams="true"
										:showSearch="true"
										:placeholder="this.$t('labels.select')"
										sortBy="asc"
										:title="this.$t('labels.special-events')"
										componentId="specialEventSubFilter"
										:filterLabel="this.$t('labels.special-events')"
										nestedField="ticket"
										:dataField="$field('ticket.special_event')+'.raw'"
										:react="{ and: getOtherFilterIds('specialEventSubFilter') }"
										:defaultQuery="() => defaultQueryFilter()"
									/>
									
									<multi-dropdown-list
										className="filter-vertical"
										:URLParams="true"
										:showSearch="true"
										:placeholder="this.$t('labels.select')"
										sortBy="asc"
										componentId="riskFilter"
										:title="this.$t('labels.increased-risk')"
										:filterLabel="this.$t('labels.risk')"
										nestedField="ticket"
										:dataField="$field('ticket.risk')+'.raw'"
										:react="{ and: getOtherFilterIds('riskFilter') }"
										:defaultQuery="() => defaultQueryFilter()"
									/>

									<multi-dropdown-list
										className="filter-vertical"
										:URLParams="true"
										:showSearch="true"
										:placeholder="this.$t('labels.select')"
										sortBy="asc"
										:size="1000"
										:title="this.$t('labels.language')"
										componentId="languageFilter"
										:filterLabel="this.$t('labels.language')"
										nestedField="ticket"
										:dataField="$field('ticket.language')+'.raw'"
										:react="{ and: getOtherFilterIds('languageFilter') }"
										:defaultQuery="() => defaultQueryFilter()"
									/>

									
									<multi-dropdown-list
										className="filter-vertical"
										:URLParams="true"
										:showSearch="true"
										:placeholder="this.$t('labels.select')"
										sortBy="asc"
										:size="1000"
										:title="this.$t('labels.victim-support-centre')"
										componentId="officeFilter"
										:filterLabel="this.$t('labels.victim-support-centre')"
										nestedField="ticket"
										:dataField="$field('ticket.office')+'.raw'"
										:react="{ and: getOtherFilterIds('officeFilter') }"
										:defaultQuery="() => defaultQueryFilter()"
									/>

									<multi-dropdown-list
										className="filter-vertical"
										:URLParams="true"
										:showSearch="true"
										:placeholder="this.$t('labels.select')"
										sortBy="asc"
										:size="1000"
										:title="this.$t('labels.local-office')"
										componentId="localOfficeFilter"
										:filterLabel="this.$t('labels.local-office')"
										nestedField="ticket"
										:dataField="$field('ticket.localoffice')+'.raw'"
										:react="{ and: getOtherFilterIds('localOfficeFilter') }"
										:defaultQuery="() => defaultQueryFilter()"
									/>

									<multi-dropdown-list
										className="filter-vertical"
										:URLParams="true"
										:showSearch="true"
										:placeholder="this.$t('labels.select')"
										sortBy="asc"
										:size="1000"
										:title="this.$t('labels.municipality')"
										componentId="municipalityFilter"
										:filterLabel="this.$t('labels.municipality')"
										nestedField="ticket"
										:dataField="$field('ticket.municipality')+'.raw'"
										:react="{ and: getOtherFilterIds('municipalityFilter') }"
										:defaultQuery="() => defaultQueryFilter()"
									/>

									<multi-dropdown-list
										className="filter-vertical"
										:URLParams="true"
										:showSearch="true"
										:placeholder="this.$t('labels.select')"
										sortBy="asc"
										:size="1000"
										:title="this.$t('labels.administrator')"
										componentId="userSubFilter"
										:filterLabel="this.$t('labels.administrator')"
										nestedField="users"
										dataField="users.name.raw"
										:react="{ and: getOtherFilterIds('userSubFilter') }"
										:defaultQuery="() => defaultQueryFilter()"
									/>


			
								</div>
							</div>
						</div>
					</v-guard>
					<div class="col-lg-12 col-xl-9 order-lg-1 order-xl-1">
						<reactive-component
							componentId="eventsList"
							:URLParams="true"
							:react="{ and: getOtherFilterIds('') }"
				
							:defaultQuery="
								() => ({
									query: this.defaultQueryFilter(),
									aggs: {
										parent: {
											terms: {
												field: this.$field('parent_label') + '.raw',
												order: {
													_key: 'asc',
												},
												size: 1000,
											},
											aggs: {
												child: {
													terms: {
														field: this.$field('child_label') + '.raw',
														order: {
															_key: 'asc',
														},
														size: 1000,
													},
													aggs: {
														year: {
															terms: {
																field: 'year.raw',
																order: {
																	_key: 'asc',
																},
																min_doc_count: 0,
																size: 100,
															},
														},
														actiontime: {
															terms: {
																field: 'year.raw',
																order: {
																	_key: 'asc',
																},
																min_doc_count: 0,
																size: 100,
															},
															aggs: {
																time: {
																	sum: {
																		field: 'action_time',
																	},
																},
															},
														},
													},
												},
												year: {
													terms: {
														field: 'year.raw',
														order: {
															_key: 'asc',
														},
														min_doc_count: 0,
														size: 100,
													},
												},
											},
										},
										month: {
											terms: {
												field: 'date.month_of_years',
												order: {
													_key: 'asc',
												},
												size: 100,
											},
											aggs: {
												year: {
													terms: {
														script: 'if (doc[\'created_at\'].size() != 0) doc[\'created_at\'].value.getYear()',
														order: {
															_key: 'asc',
														},
														min_doc_count: 0,
														size: 100,
													},
												},
											},
										},
										quarter: {
											terms: {
												field: 'quarter.raw',
												order: {
													_key: 'asc',
												},
											},
											aggs: {
												year: {
													terms: {
														field: 'year.raw',
														order: {
															_key: 'asc',
														},
														min_doc_count: 0,
														size: 100,
													},
												},
											},
										},
										total_year: {
											terms: {
												field: 'year.raw',
												order: {
													_key: 'asc',
												},
												min_doc_count: 0,
												size: 100,
											},
										},
									
										
									},
								})
							"
						>
							<div slot-scope="{ aggregations, hits, setQuery }">
								
								<div class="row">
								<!-- 	<div class="col-12">
										<statistic-widget
											:yearsSelected="yearsSelected"
											:data="aggregations.ticket.language.buckets"
											:title="$t('labels.language')"
										></statistic-widget>
									</div>
									<div class="col-12">
										<statistic-widget
											:yearsSelected="yearsSelected"
											:data="aggregations.ticket.gender.buckets"
											:title="$t('labels.gender')"
										></statistic-widget>
									</div> -->
									<div class="col-12">
											<statistic-widget-total :yearsSelected="yearsSelected"
												:data="aggregations.total_year.buckets"
												:title="$t('labels.total')"></statistic-widget-total>
										</div>
									<div class="col-12">
										<statistic-widget-hierarchical
											:yearsSelected="yearsSelected"
											:data="aggregations.parent.buckets"
											:title="$t('labels.number-of-events')"
											:hideParent="[]"
										></statistic-widget-hierarchical>
									</div>
									<div class="col-12">
										<statistic-widget-action-time
											:yearsSelected="yearsSelected"
											:data="aggregations.parent.buckets"
											:title="$t('labels.time-spent-in-minutes')"
											:hideParent="[]"
										></statistic-widget-action-time>
									</div> 
							
								</div>
							</div>
						</reactive-component>
					</div>
				</div>
			</reactive-base>
			<ScrollTopArrow />
		</div>
	</layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import _ from 'lodash';
import StatisticWidgetTotal from '@/components/widgets/StatisticWidgetTotal.vue';
import StatisticWidget from '@/components/widgets/StatisticWidget.vue';
import StatisticWidgetTotalEvent from '@/components/widgets/StatisticWidgetTotalEvent.vue';
import StatisticWidgetActionTime from '@/components/widgets/StatisticWidgetActionTimeEvent.vue';
import statisticWidgetHierarchical from '@/components/widgets/statisticWidgetHierarchicalEvent.vue';
import ScrollTopArrow from '@/components/ScrollToTop';
import InfoBox from '@/components/InfoBox';

export default {
	components: {
		LayoutMain,
		StatisticWidget,
		StatisticWidgetTotal,
		StatisticWidgetTotalEvent,
		StatisticWidgetActionTime,
		statisticWidgetHierarchical,
		ScrollTopArrow,
		InfoBox,
	},
	data() {
		return {
			selectedStatistic: 'events',
			statistics: [
				{ text: this.$t('labels.tickets'), value: 'tickets' },
				{ text: this.$t('labels.events'), value: 'events' },
			],
			elasticUrl: process.env.VUE_APP_API_ELASTIC,
			getSortBy: 'id',
			isLoading: false,
			allMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
			customAge: ['Barn 0-11', 'Ungdom 12-17', 'Ung vuxen 18-25', 'Vuxen 26-64', 'Äldre 65 +', 'Uppgift saknas'],
			facets: ['yearFilter', 'monthFilter', 'quarterFilter', 'languageFilter', 'genderFilter', 'ageFilter', 'categoryFilter', 'firstContactFilter', 'crimeFilter', 'officeFilter', 'localOfficeFilter', 'municipalityFilter', 'userSubFilter', 'crimeRelationSubFilter', 'riskFilter', 'specialEventSubFilter'],
			yearsSelected: [],
		};
	},
	methods: {
		defaultQueryFilter() {
			const excludedYears = ['2017', '2018', '2019', '2020'];

			const queryObject = {
				query: {
					bool: {
						should: [
							{
								bool: {
									must_not: [
										{
											term: {
												'parent_label.raw': 'Intervju',
											},
										},
										{
											term: {
												'parent_label.raw': 'System',
											},
										},
										{
											term: {
												'parent_label.raw': 'Kommentar',
											},
										},
										{
											terms: {'year.raw': excludedYears},
										}
									]
								}
							},
						],
					}
				}
			}

			return queryObject;
		},
		switchStatistic() {
			this.$router.push({ name: 'tickets.statistics.new' });
		},
		groupedResultsByCrime(data) {},
		renameMonthsLabel(label) {
			label = this.allMonths[this.allMonths.indexOf(label.slice(0, 3))] ?? '';

			return label;
		},
		sortMonthByLabel(data) {
			let months = data.slice().sort((a, b) => {
				return this.allMonths.indexOf(a.key.slice(0, 3)) - this.allMonths.indexOf(b.key.slice(0, 3));
			});

			return months;
		},
		customSortAge(data) {
			let newData = data.slice().sort((a, b) => {
				return this.customAge.indexOf(a.key) - this.customAge.indexOf(b.key);
			});

			return newData;
		},
		getOtherFilterIds(id) {
			let ids = this.facets.filter((f) => f !== id).map((f) => f);

			return ids;
		},
		setSelectedYears(y) {
			if (y !== undefined && y !== null) {
				this.yearsSelected = y;
			} else {
				this.yearsSelected = [];
			}
		},
	},
	computed: {
		user() {
			return this.$store.getters.user;
		},
	},
};
</script>


<style>
.level-1 > th {
	padding-left: 1em;
}
</style>
