import { render, staticRenderFns } from "./StatisticWidgetActionTimeEvent.vue?vue&type=template&id=1b42d8ef&scoped=true&"
import script from "./StatisticWidgetActionTimeEvent.vue?vue&type=script&lang=js&"
export * from "./StatisticWidgetActionTimeEvent.vue?vue&type=script&lang=js&"
import style0 from "./StatisticWidgetActionTimeEvent.vue?vue&type=style&index=0&id=1b42d8ef&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b42d8ef",
  null
  
)

export default component.exports