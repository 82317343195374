<template>
	<div class="widget statistic-widget">
		<div class="widget-header">
			<div class="widget-header-label">
				<h3>{{ title }}</h3>
			</div>
			<div class="widget-header-toolbar">
				<div class="actions">
					<!-- <div class="btn btn-grey btn-bold ml-3"><i class="fas fa-download"></i></div> -->
				</div>
			</div>
		</div>
		<div class="widget-body no-padding">
			<table class="vuetable">
				<thead>
					<tr>
						<th width="35%">{{ title }}</th>
						<th v-for="label in labels" :key="label">{{ label }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="action in actions" :key="action.key">
						<td :class="!action.child ? 'level-1' : 'level-0'">{{ action.key }}</td>

						<td v-for="year in years(action)" :key="year.key">
							{{ year.doc_count == 0 ? '' : year.doc_count.toLocaleString('sv-SE') }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
import moment from 'moment';

export default {
	data() {
		return {
			moment: moment,
			excludedYears: ['2017', '2018', '2019', '2020'],
		};
	},
	props: {
		data: Array,
		title: String,
		yearsSelected: Array,
		hideParent: Array,
	},
	computed: {
		labels() {
			let labels = [];
			this.data.forEach((value) => {
				if (Object.prototype.hasOwnProperty.call(value, 'year')) {
					value.year.buckets.forEach((bucket) => {
						if (!labels.includes(bucket.key)) {
							if (this.yearsSelected.length) {
								if (this.yearsSelected.includes(bucket.key)) {
									labels.push(bucket.key);
								}
							} else if(!this.excludedYears.includes(bucket.key)) {
								labels.push(bucket.key);
							}
						}
					});
				}
			});

			return labels.sort();
		},
		actions() {
			const actions = this.data.map((actionParent) => {
				if(this.hideParent.includes(actionParent.key)) {
					return [];
				}
				let actionChilds = actionParent.child.buckets.map((second) => {
					return second;
				});
				return [actionParent, ...actionChilds];
			});

			return actions.flat();
		},
	},
	methods: {
		capitalize(word) {
			return word.trim().replace(/^\w/, (c) => c.toUpperCase());
		},
		years(agg) {
			if (!Object.prototype.hasOwnProperty.call(agg, 'child')) {
				if (this.yearsSelected.length) {
					const newYearsArray = agg.year.buckets.filter((y) => {
						if (this.yearsSelected.includes(y.key)) {
							return y;
						}
					});
					return newYearsArray;
				}else{
					const newYearsArray = agg.actiontime.buckets.filter((y) => {
						if (!this.excludedYears.includes(y.key)) {
							return y;
						}
					});
					return newYearsArray;
				}
			}

			return [];
		},
	},
};
</script>

<style scoped>
.level-0 {
	font-weight: 500;
}
.level-1 {
	padding-left: 45px !important;
}
</style>
